<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        :rules="searchRules"
        inline
      >
        <el-form-item
          label="日期:"
          prop="dateRange"
        >
          <el-date-picker
            v-model="formData.dateRange"
            :picker-options="pickerOptions"
            type="datetimerange"
            :clearable="false"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleSearch"
          />
        </el-form-item>
        <el-form-item label="关联游戏:">
          <el-select
            v-model="games"
            value-key="gameId"
            style="width: 420px;"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            @change="handleSearch"
          >
            <el-option
              v-for="item in options"
              :key="item.gameIdStr"
              :label="item.gameName"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange" @change="queryDataList(gameId.gameId)"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"

          />
        </el-form-item> -->
        <el-form-item prop="zoneId">
          <span>区:</span>
          <el-select
            v-model="formData.zoneId"
            style="width: 240px"
            placeholder="Region"
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in regionList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="AppPlat">
          <span>端:</span>
          <el-select
            v-model="formData.AppPlat"
            style="width: 240px"
            placeholder="平台"
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in AppPlatList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="right">
        <el-button
          class="download-icon"
          type="text"
          :loading="downloadLoading"
          @click="downloadGameStatics"
        >
          <i
            title="下载"
            class="fa fa-cloud-download icondown"
          />
        </el-button>
      </div>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      style="width: 100%"
      border
      :default-sort="{
        prop: formData.sortName,
        order: formData.sortType === 'desc' ? 'descending' : 'ascending'
      }"
      @sort-change="handleSortTable"
    >
      <el-table-column
        label="日期"
        prop="dt"
        min-width="120px"
      />
      <el-table-column
        label="游戏ID"
        prop="source_id"
        min-width="180px"
      />
      <el-table-column
        label="游戏名"
        prop="game_name"
        min-width="120px"
      />
      <!-- <el-table-column
        label="客户端"
        prop="app_plat"
        min-width="120px"
      />
      <el-table-column
        label="地区"
        prop="zone_id"
        :formatter="zoneFormatter"
        min-width="120px"
      /> -->
      <el-table-column
        label="总在线人数"
        prop="total_play_num"
        min-width="100px"
        sortable="total_play_num"
      />

      <!-- <el-table-column
        label="日活"
        prop="total_play_num"
        min-width="100px"
        sortable="custom"
      /> -->
      <el-table-column
        label="总在线时长(min)"
        prop="total_play_time"
        :formatter="timeFormatter"
        width="180px"
        sortable="custom"
      />
      <el-table-column
        label="人均在线时长(min)"
        prop="avg_play_time"
        :formatter="timeFormatter"
        width="180px"
        sortable="custom"
      />
      <el-table-column
        label="租号使用率"
        prop="rent_number_ratio"
        width="180px"
        sortable="custom"
      />
    </el-table>

    <Pagination
      v-show="!listInfo.loading"
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import { format } from 'fecha'
//
import { getGameStatics, downloadGameStatics } from '@/api/finance.js'

import { cloneDeep } from 'lodash'
import { searchGameList } from '@/api/gameList'
import { round } from '@/utils'
const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const getYesterday = () => {
  // const hours = new Date().getHours()
  // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
  // const days = hours > 9 ? 1 : 2
  // const targetDay = new Date(Date.now() - days * 24 * 60 * 60 * 1000).getTime()
  // console.log([format(new Date(new Date(new Date().toLocaleDateString()).getTime() - (6 * 24 * 60 * 60 * 1000)), 'YYYY-MM-DD HH:mm:ss'), format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss')])
  // return [format((new Date(new Date(new Date().toLocaleDateString()).getTime() - (6 * 24 * 60 * 60 * 1000)), 'YYYY-MM-DD HH:mm:ss')), format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss')]
  return [format(new Date(new Date(new Date().toLocaleDateString()).getTime() - (6 * 24 * 60 * 60 * 1000)), 'YYYY-MM-DD HH:mm:ss'), format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss')]
}
const formKey = 'GAME_DATA_FORM'
const translateList2Map = list => {
  return list.reduce((map, item) => {
    map[item.value] = item
    return map
  }, {})
}

const regionList = [
  { name: 'All', value: 0 },
  { name: 'Europe', value: 1 },
  { name: 'America', value: 2 },
  { name: 'Arab', value: 3 },
  { name: 'Asia', value: 4 },
  { name: 'Other', value: 5 }
]

const regionListMap = translateList2Map(regionList)

export default {
  name: 'GameData',
  data () {
    return {
      options: [],
      gameId: '',
      gameName: '',
      // 平台1:android2:Ios.3:AllChannel
      AppPlatList: [
        { name: 'All', value: '' },
        { name: 'Android', value: 'ANDROID' },
        { name: 'iOS', value: 'IOS' },
        { name: 'H5', value: 'H5' },
        { name: 'PC', value: 'PC' }
      ],
      AppPlatListMap: {
        '': 'All',
        ANDROID: 'Android',
        IOS: 'iOS',
        H5: 'H5',
        PC: 'PC'
      },
      regionList,
      games: {},
      formData: {
        dateRange: getYesterday(),
        zoneId: 0,
        AppPlat: '',
        sortName: 'total_play_num',
        sortType: 'desc',
        pageNum: 1,
        pageSize: 20
      },
      timeOptionRange: null,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              const start = format(
                new Date(new Date().toLocaleDateString()),
                dateFormat
              )
              const end = format(new Date().getTime(), dateFormat)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const start = format(
                new Date(new Date(new Date().toLocaleDateString()).getTime() - (24 * 60 * 60 * 1000)),
                dateFormat
              )
              const end = format(new Date().getTime(), dateFormat)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近7天',
            onClick (picker) {
              const start = format(
                new Date(new Date(new Date().toLocaleDateString()).getTime() - (6 * 24 * 60 * 60 * 1000)),
                dateFormat
              )
              const end = format(new Date().getTime(), dateFormat)
              picker.$emit('pick', [start, end])
            }
          }
          // {
          //   text: '最近15天',
          //   onClick (picker) {
          //     const start = format(
          //       new Date().getTime() - 15 * 24 * 60 * 60 * 1000,
          //       dateFormat
          //     )
          //     const end = format(new Date().getTime(), dateFormat)
          //     picker.$emit('pick', [start, end])
          //   }
          // },
          // {
          //   text: '最近30天',
          //   onClick (picker) {
          //     const start = format(
          //       new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
          //       dateFormat
          //     )
          //     const end = format(new Date().getTime(), dateFormat)
          //     picker.$emit('pick', [start, end])
          //   }
          // },
          // {
          //   text: '本月',
          //   onClick (picker) {
          //     const n = new Date().getDate() - 1
          //     const start = format(
          //       new Date(new Date().toLocaleDateString()).getTime() -
          //         n * 24 * 60 * 60 * 1000,
          //       dateFormat
          //     )
          //     const end = format(new Date().getTime(), dateFormat)
          //     picker.$emit('pick', [start, end])
          //   }
          // },
          // {
          //   text: '上月',
          //   onClick (picker) {
          //     const start = format(
          //       new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
          //       dateFormat
          //     )
          //     const end = format(new Date().getTime(), dateFormat)
          //     picker.$emit('pick', [start, end])
          //   }
          // }
        ]
      },
      // pickerOptions: {
      //   onPick: ({ minDate, maxDate }) => {
      //     if (minDate && !maxDate) {
      //       this.timeOptionRange = minDate
      //     }
      //     if (maxDate) {
      //       this.timeOptionRange = null
      //     }
      //   },
      //   disabledDate: (time) => {
      //     const hours = new Date().getHours()
      //     const days = hours > 9 ? 1 : 2
      //     const dateTime = time.getTime()
      //     const timeOptionRange = this.timeOptionRange
      //     let overflowmMaxRange = false
      //     const maxSecond = 6 * 24 * 60 * 60 * 1000
      //     if (timeOptionRange) {
      //       if ((time.getTime() > timeOptionRange.getTime() + maxSecond) || (time.getTime() < timeOptionRange.getTime() - maxSecond)) {
      //         overflowmMaxRange = true
      //       }
      //     }
      //     return overflowmMaxRange || dateTime > new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
      //   }
      // },
      searchRules: {
        // dateRange: [{ required: true, message: 'Date range is required' }]
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      downloadLoading: false
    }
  },
  mounted () {
    const storageFormData = sessionStorage.getItem(formKey)
    if (storageFormData) {
      this.listInfo.total = 9999
      this.formData = JSON.parse(storageFormData)
    }
    this.queryDataList()
  },
  methods: {
    remoteMethod (query) {
      if (!query) return
      this.loading = true
      searchGameList({ keyWord: query, pageNum: 1, pageSize: 30 })
        .then(res => {
          if (res.code === 200) {
            this.options = res.data.list.map(item => {
              // this.gameId = item.gameIdStr
              return {
                gameId: item.gameIdStr,
                gameName: item.gameName,
                imageVertical: item.imageVertical,
                image: item.image,
                tips: item.tag,
                platform: item.platform,
                startMode: item.startMode,
                rentalSupport: item.rentalSupport
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    zoneFormatter (row, column, cellValue, index) {
      return regionListMap?.[cellValue]?.name || '-'
    },
    timeFormatter (row, column, cellValue, index) {
      return round(cellValue / 60, -1)
    },
    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startDt, endDt] = form.dateRange || []
      delete form.dateRange
      return {
        ...form,
        startDt,
        endDt
      }
    },
    handleSortTable (row) {
      let { prop, order } = row
      if (order === null) {
        order = this.formData.sortType === 'asc' ? 'descending' : 'ascending'
        row.column.order = order
      }
      this.formData.sortName = prop
      const sortType =
        order === 'ascending' ? 'asc' : order === 'descending' ? 'desc' : ''
      this.formData.sortType = sortType
      this.queryDataList()
    },
    downloadGameStatics () {
      this.downloadLoading = true
      const query = this.formatQuery(this.formData)
      delete query.pageNum
      delete query.pageSize
      downloadGameStatics(query)
        .then(res => {
          if (res.code === 200) {
            if (res?.data?.url) {
              this.downloadFile(res?.data?.url)
            } else {
              this.$message.error('下载失败, 请重试')
            }
          }
        })
        .finally(() => {
          this.downloadLoading = false
        })
    },
    downloadFile (url) {
      var a = document.createElement('a')
      a.href = url
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
      this.formData.gameId = this.games.gameId
    },
    queryDataList () {
      // if (this.formData.games) {
      //   this.formData.gameId = this.formData.games.gameId
      // }

      this.$refs.form.validate(valid => {
        if (valid) {
          this.listInfo.loading = true
          const query = this.formatQuery(this.formData)
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          query.gameId = this.games.gameId || ''
          // console.log(query, '123')
          getGameStatics(query)
            .then(res => {
              if (res.code === 200) {
                this.listInfo.list = res.data?.list || []
                this.listInfo.total = res.data?.total || 0
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item {
  span {
    margin-right: 10px;
  }
}
.overview {
  display: flex;
  // padding: 0 0 20px 0;
  min-width: 880px;
  margin-bottom: 10px;
  .items {
    // width: 210px;
    padding: 0 30px;
    height: 100px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .num {
      font-size: 26px;
      margin-bottom: 10px;
      .unit {
        font-size: 14px;
      }
    }
    .name {
      font-size: 12px;
    }
  }
}
.pre-header {
  height: 46px;
  .select-all {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    font-size: 14px;
    margin: 10px 0;
    padding: 10px 20px;
    cursor: pointer;
    background: #f5f7fa;
    box-sizing: border-box;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
    &::before {
      content: "";
      display: block;
      height: 10px;
      background: white;
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
    }
    &:hover {
      color: cornflowerblue;
    }
  }
}

.download-icon {
  display: flex;
  align-items: center;
  .icondown {
    font-size: 35px;
  }
}
</style>
